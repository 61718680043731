import React from "react"

import { Breadcrum, SEO } from "../components/index"


const NotFoundPage = () => (
  <>
    <SEO title="404: Page Not found" noindex={true} />
    <Breadcrum pages={[{
      text: '404: Page Not Found',
      active: true,
      to: '/'
    }]} />
    <h1>Page not found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default NotFoundPage
